/*------------------------------------*\
        ____            _  __    
       / __"| u        |"|/ /    
      <\___ \/         | ' /     
       u___) |       U/| . \\u   
       |____/>>        |_|\_\    
        )(  (__)     ,-,>> \\,-. 
       (__)           \.)   (_/  

\*------------------------------------*/

/*------------------------------------*\
  ASSETS AND BUILDUP
\*------------------------------------*/

@import 'poly-fluid-sizing';
@import 'include-media';

@font-face {
    font-family: 'Deputy Beta';
    src: url('../fonts/DeputyBeta-Regular.woff2') format('woff2'),
        url('../fonts/DeputyBeta-Regular.woff') format('woff');

    font-style: normal;
    font-weight: 300;
}

$padding:1.5rem;
$paddingmobile:1rem;
$transition:color 200ms ease-in;



html {
    @include poly-fluid-sizing('font-size',
        (320px: 14px, 768px:16px, 1024px:16px, 1400px:18px, 1920px:24px));
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

html,
body {
    margin: 0;
    font-family: 'Deputy Beta', sans-serif;
    letter-spacing: 0.065em;
}

a {
    color: red;
    text-decoration: none;
    transition:$transition;
}
a:hover{
    color:black;
}

/*------------------------------------*\
  FIXED INFORMATION
\*------------------------------------*/

.information-fixed,
.information-fixed-links {
    position: fixed;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;
    padding: $padding;
    box-sizing: border-box;
    z-index: 2;
    mix-blend-mode: difference;
    color: white;
}

.information-fixed p,
.information-fixed-links p {
    margin: 0;
}

.information-fixed a{
    color:rgb(0,100,250);
}
.information-fixed a:hover {
    color: white;
}

.information-fixed-left,
.information-fixed-right {
    position: relative;
    width: auto;
    height: auto;
    float: left;
}

.information-fixed-right {
    float: right;
    cursor: pointer;
}

.information-fixed-links {
    mix-blend-mode: normal;
    transition: none;
    display:inline-block;
    width:auto;
    padding:0;
    margin:$padding;
}
.information-fixed-links:hover{
    mix-blend-mode: difference;
}

.information-link-entry a{
    color:red;
    transition:none;
}
.information-link-entry a:hover{
    color:white;
}


.information-link-entry p {
    display: inline-block;
    margin: 0;
}
/*------------------------------------*\
  PROJECTS
\*------------------------------------*/

.project-list {
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
}

.project img.lazy {
    width: 100%;
    display: block;
    height: auto;
    image-rendering: pixelated;
}

.project img.done {
    image-rendering: optimizeQuality;
}

.project video {
    display: block;
    width: 100%;
    height: auto;
}

/*------------------------------------*\
  INFORMATION BOTTOM
\*------------------------------------*/

.information-bottom {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: $padding;
    box-sizing: border-box;
    background-color: white;
    z-index: 3;
}

.information-bottom p:first-of-type {
    margin-top: 0;
}

#ui-index p {
    display: inline;
    margin: 0;
}

.information-list-entry {
    cursor: pointer;
    transition: color 200ms ease-in;
}

.information-list-entry:hover {
    color: red;
}


/*------------------------------------*\
  SIZE CORRECTION
\*------------------------------------*/


@include media("<=tablet") {

    .information-bottom,
    .information-fixed{
        padding: $paddingmobile;
    }
    .information-fixed-links{
        margin:$paddingmobile;
    }

}